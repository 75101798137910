// src/components/Statistics.js
import { useState, useEffect } from "react";
import { DataGrid } from '@mui/x-data-grid';
import { Box, Typography, Button, Dialog, DialogContent, DialogTitle, LinearProgress, MenuItem, Select, FormControl, InputLabel } from '@mui/material';
import axios from 'axios';
import { collection, getDocs, query } from "@firebase/firestore";
import { firestore } from '../firebase';

const typeOfPlatforms = [
  // List of vehicle platforms
  "LandRover", "OUV (FordEverest)", "LUV (Agilis)", "MB240 RecceJeep", "MB240 SpikeJeep", 
  "MB290GS", "MB290 (GRS)", "MB290 (MPSTAR)", "F550 (Ambulance)", "F550 (ComdPost)", 
  "LightStrike (MK II AGL)", "LightStrike (MK II VLSS)", "LightStrike (MK II UTILITY)", 
  "3TON GEN (PSD)", "5TONGS", "5TON (BCS)", "5TON (BLS/BFI)", "5TON (PTG)", 
  "5TON (T-HUB)", "5TON (CP)", "5TON (S/T)", "5TON (MTV CRANE)", "6TONGS", 
  "6TON (BCS)", "6TON (PTG)", "6TON (FSD)", "DNCC (ETHAN)", "HMCT", "HMCT (A1TaCC)", 
  "HMCT (SHIKRA)", "HMCT (SPYDER)", "LARC V", "WheelRecoveryVehicle (WRV)", "RD", 
  "VSD", "V15", "UAV (Parrot & Css)", "LandCruiser SUV", "MiniBus", "Twin Cab"
];

const typeOfUnits = [
  // List of military units
  "All Units", "FSG", "ASI", "BFI", "3DIV", "9DIV", "8SAB/40 SAR", "41 SAR", "48 SAR", 
  "7 SIB", "1 GDS", "3 GD", "ADF", "ATEC", "2 SIB", "5 SIR", "SOI", 
  "ATI OPFOR", "ATI AUTC", "ITI OPFOR", "MTI MBTC 1", "ICTC 2", "11C4I/SMII", 
  "3 TPT BN", "GTC", "30 SCE", "38 SCE", "SFLS (CDO)", "RANGER", "33 CSSB", 
  "CATC", "XIA", "3 FLOT", "1516", "GBAD", "RSAF", "Others"
];

const classifyFrame = (date) => {
  const entryDate = new Date(date);
  if (entryDate < new Date("2024-09-26")) return 'f1';
  if (entryDate <= new Date("2024-10-15")) return 'f2';
  if (entryDate <= new Date("2024-11-03")) return 'f3';
  return 'f4';
};

// Initialize mappings with units nested inside platforms
const initializeMappings = () => {
  const mappings = {};
  typeOfPlatforms.forEach(platform => {
    mappings[platform.toLowerCase()] = {};
    typeOfUnits.forEach(unit => {
      mappings[platform.toLowerCase()][unit] = {
        f1: 0,
        f2: 0,
        f3: 0,
        f4: 0
      };
    });
  });
  return mappings;
};

export default function Statistics() {
  const [statistics, setStatistics] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectedUnit, setSelectedUnit] = useState(typeOfUnits[0]);
  const [loadingPopup, setLoadingPopup] = useState(false);
  const [progressValue, setProgressValue] = useState(0);
  const [currentUserName, setCurrentUserName] = useState('');

  useEffect(() => {
    fetchStatistics();
  }, []);

  const fetchStatistics = async () => {
    setLoading(true);
    try {
      const response = await axios.get('/api/fetch-stats');
      setStatistics(response.data || []);
    } catch (error) {
      console.error("Error fetching statistics:", error);
    } finally {
      setLoading(false);
    }
  };

  const fetchUsersFromFirestore = async () => {
    try {
      const q = query(collection(firestore, "users"));
      const querySnapshot = await getDocs(q);
      return querySnapshot.docs.map(doc => doc.data());
    } catch (error) {
      console.error("Error fetching users from Firestore:", error);
    }
  };

  const fetchMileageRecordsFromFirestore = async (userId) => {
    try {
      const q = query(collection(firestore, "records", userId, "mileage"));
      const querySnapshot = await getDocs(q);
      return querySnapshot.docs.map(doc => doc.data());
    } catch (error) {
      console.error(`Error fetching mileage records for user ${userId}:`, error);
    }
  };

  const handleRefresh = async () => {
    const vehicleMappings = initializeMappings();

    setLoadingPopup(true);
    setProgressValue(0);

    try {
      const users = await fetchUsersFromFirestore();

      for (let i = 0; i < users.length; i++) {
        const user = users[i];
        setCurrentUserName(user.name);
        const mileageRecords = await fetchMileageRecordsFromFirestore(user.uid);

        mileageRecords.forEach(record => {
          const frame = classifyFrame(record.detailDate);
          const vehicleType = record.vehPlatform.toLowerCase();
          const unit = user.unit;

          if (vehicleMappings[vehicleType] && vehicleMappings[vehicleType][unit]) {
            vehicleMappings[vehicleType][unit].f1 += frame === 'f1' ? Number(record.distance) : Number(0);
            vehicleMappings[vehicleType][unit].f2 += frame === 'f2' ? Number(record.distance) : Number(0);
            vehicleMappings[vehicleType][unit].f3 += frame === 'f3' ? Number(record.distance) : Number(0);
            vehicleMappings[vehicleType][unit].f4 += frame === 'f4' ? Number(record.distance) : Number(0);
          }
        });

        setProgressValue(Math.round(((i + 1) / users.length) * 100));
      }

      console.log(vehicleMappings);

      await axios.post('/api/update-stats', { vehicleMappings });
      fetchStatistics();
    } catch (error) {
      console.error("Error updating statistics:", error);
    } finally {
      setLoadingPopup(false);
    }
  };

  const filteredStatistics = selectedUnit === "All Units" 
    ? statistics 
    : statistics.filter(stat => stat.unit === selectedUnit);

  const columns = [
    { field: 'type_of_platform', headerName: 'Vehicle Type', width: 200 },
    { field: 'unit', headerName: 'Unit', width: 100 },
    { field: 'f1', headerName: 'Before 26 Sep', width: 150 },
    { field: 'f2', headerName: '27 Sep - 15 Oct', width: 150 },
    { field: 'f3', headerName: '16 Oct - 3 Nov', width: 150 },
    { field: 'f4', headerName: 'After 4 Nov', width: 150 }
  ];

  return (
    <Box sx={{ flexGrow: 1, padding: 3 }}>
      <Typography variant="h4" gutterBottom>
        Vehicle Mileage Statistics
      </Typography>

      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
        <FormControl sx={{ minWidth: 200 }}>
          <InputLabel>Unit</InputLabel>
          <Select
            value={selectedUnit}
            onChange={(e) => setSelectedUnit(e.target.value)}
            label="Unit"
          >
            {typeOfUnits.map((unit) => (
              <MenuItem key={unit} value={unit}>{unit}</MenuItem>
            ))}
          </Select>
        </FormControl>

        <Button variant="contained" sx={{ alignSelf: 'center' }} onClick={handleRefresh}>
          Refresh Statistics
        </Button>
      </Box>

      {loading ? <LinearProgress /> : (
        <DataGrid
          rows={filteredStatistics}
          columns={columns}
          pageSize={5}
          rowsPerPageOptions={[5]}
          autoHeight
        />
      )}

      <Dialog open={loadingPopup}>
        <DialogTitle>Refreshing Statistics</DialogTitle>
        <DialogContent>
          <Typography>Processing user: {currentUserName}</Typography>
          <LinearProgress variant="determinate" value={progressValue} />
        </DialogContent>
      </Dialog>
    </Box>
  );
}
